import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

function Sidebar() {
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    console.log('Fetching sidebar data...');
    fetch('/api/sidebar')
      .then(response => response.json())
      .then(data => {
        console.log('Sidebar data received:', data);
        setRecentPosts(data);
      })
      .catch(error => console.error('Error fetching sidebar data:', error));
  }, []);

  console.log('Rendering sidebar with posts:', recentPosts);

  return (
    <div className="sidebar">
      <h3>Recent Posts</h3>
      {recentPosts.length === 0 ? (
        <p>Loading recent posts...</p>
      ) : (
        <ul>
          {recentPosts.map(post => (
            <li key={post.slug}>
              <Link to={`/post/${post.slug}`}>{post.title}</Link>
            </li>
          ))}
        </ul>
      )}
      
      <h3>Connect with me</h3>
      <div className="social-icons" style={{
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '10px',
        marginTop: '0.5rem'
      }}>
        <a href="https://www.linkedin.com/in/simonedisomma" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="lg" />
        </a>
        <a href="https://www.instagram.com/simonedisomma" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="lg" />
        </a>
        <a href="https://www.facebook.com/simonedisomma" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="lg" />
        </a>
        <a href="https://twitter.com/simonedisomma" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </a>
      </div>
    </div>
  );
}

export default Sidebar;